














































import { Component, Prop, Vue } from "vue-property-decorator";
import Listingbody from "@/components/UIComponents/Listingbody.vue";
import { CropVarietyStore } from "@/store/modules";

@Component({
  components: {
    Listingbody
  }
})
export default class Varietylist extends Vue {
  private get cropVariety() {
    return CropVarietyStore.CropVariety;
  }

  private get similarCropVariety() {
    return CropVarietyStore.SimilarCropVarieties;
  }

  private async created() {
    await CropVarietyStore.getCropVarietyDetailById({
      id: Number(this.$route.query.varietyId),
      lang: this.$i18n.locale
    });
    await CropVarietyStore.getSimilarCropVariety({
      cropId: Number(this.$route.query.varietyId),
      lang: this.$i18n.locale
    });
  }
}
